<template>
  <div class="main-body">
    <div class="table-search">
      <a-form
        :model="formState"
        layout="inline"
        autocomplete="off"
        @submit="onLoadFileList"
      >
        <a-form-item label="文件名称">
          <a-input
            v-model:value="formState.keyword"
            placeholder="请输入文件名称"
          />
        </a-form-item>
        <a-form-item label="文件分组">
          <a-tree-select
            class="medium-input"
            v-model:value="formState.group_id"
            placeholder="请选择文件分组"
            tree-default-expand-all
            :tree-data="treeData"
            :field-names="{
              children: 'children',
              label: 'name',
              key: 'group_id',
              value: 'group_id',
            }"
          >
          </a-tree-select>
        </a-form-item>
        <a-form-item label="存储方式">
          <a-select
            class="medium-input"
            placeholder="请选择存储方式"
            v-model:value="formState.storage"
          >
            <a-select-option value="">全部</a-select-option>
            <a-select-option
              :value="item.code"
              v-for="(item, index) in engineList"
              :key="index"
              >{{ item.title }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item class="search-btn">
          <a-button type="primary" html-type="submit">搜索</a-button>
        </a-form-item>
      </a-form>
    </div>
    <a-radio-group
      v-model:value="formState.file_type"
      class="margin-bottom16"
      @change="onLoadFileList"
    >
      <a-radio-button value="">全部</a-radio-button>
      <a-radio-button value="10">图片</a-radio-button>
      <a-radio-button value="20">视频</a-radio-button>
      <a-radio-button value="30">附件</a-radio-button>
    </a-radio-group>

    <a-table
      :dataSource="formState.image_list"
      :columns="columns"
      :pagination="pagination"
      @change="onLoadFileList"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'full_path'">
          <div class="image-preview" v-if="record.file_type === 10">
            <a-image :src="record.full_path" :height="40" :width="40" />
          </div>
          <div v-else-if="record.file_type === 20">视频</div>
          <div v-else-if="record.file_type === 30">附件</div>
        </template>
        <template v-if="column.key === 'file_size'">
          {{ (record.file_size / 1024).toFixed(2) }}
        </template>
        <template v-if="column.key === 'action'">
          <span>
            <a @click="updateFileInfo(record)">编辑</a>
            <a-divider type="vertical" />
            <a @click="deleteFileConfirm(record)">删除</a>
          </span>
        </template>
      </template>
    </a-table>
  </div>

  <a-modal
    v-model:visible="update_visible"
    title="编辑文件"
    @ok="handleSubmit"
    :width="500"
  >
    <a-form ref="formRef" :model="formUpdateState">
      <a-form-item has-feedback label="文件名称" name="file_name">
        <a-input
          v-model:value="formUpdateState.file_name"
          type="text"
          autocomplete="off"
        />
      </a-form-item>
      <a-form-item has-feedback label="上级分组" name="group_id">
        <a-tree-select
          v-model:value="formUpdateState.group_id"
          placeholder="Please select"
          allow-clear
          tree-default-expand-all
          :tree-data="treeData"
          :field-names="{
            children: 'children',
            label: 'name',
            key: 'group_id',
            value: 'group_id',
          }"
        >
        </a-tree-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { defineComponent, ref, reactive, computed, onMounted } from "vue";
import { Modal, message } from "ant-design-vue";
import * as Api from "@/addons/storage/api";
import "./style/file.less";
export default defineComponent({
  setup() {
    const formState = reactive({
      file_name: "",
      storage: "",
      source: "0",
      file_type: "",
      image_list: [],
      total: 0,
      current_page: 0,
      per_page: 0,
      group_id: 0,
    });

    const treeData = ref();

    // 获取分组数据
    const getGroupList = () => {
      Api.group_list().then((res) => {
        treeData.value = res.data.list;
      });
    };

    const engineList = ref();
    //获取存储引擎列表
    const getEngineList = () => {
      Api.engine_list().then((res) => {
        engineList.value = res.data;
      });
    };

    const pagination = computed(() => ({
      total: formState.total,
      current: formState.current_page,
      pageSize: formState.per_page,
    }));

    const onLoadFileList = (page) => {
      Api.file({
        page: page?.current,
        group_id: formState.group_id,
        keyword: formState.keyword,
        storage: formState.storage,
        file_type: formState.file_type,
      }).then((res) => {
        formState.image_list = res.data.data;
        formState.total = res.data.total;
        formState.current_page = res.data.current_page;
        formState.per_page = res.data.per_page;
      });
    };

    onMounted(() => {
      getGroupList();
      getEngineList();
      onLoadFileList();
    });

    const update_visible = ref(false);

    const formUpdateState = reactive({
      file_id: 0,
      file_name: "",
      group_id: 0,
    });

    // 编辑
    const updateFileInfo = (info) => {
      formUpdateState.file_id = info.file_id;
      formUpdateState.file_name = info.file_name;
      formUpdateState.group_id = info.group_id;
      update_visible.value = true;
      console.log(info);
    };

    // 提交更新
    const handleSubmit = () => {
      Api.update_file(formUpdateState).then((res) => {
        if (res.status === 200) {
          message.success(res.message);
        } else {
          message.error(res.message);
        }
        update_visible.value = false;
        onLoadFileList();
      });
    };

    const deleteFileConfirm = (info) => {
      Modal.confirm({
        title: "确定要删除【" + info.file_name + "】吗?",
        content: "删除后将无法恢复，请谨慎操作！",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          Api.delete_file({ file_id: info.file_id }).then((res) => {
            if (res.status === 200) {
              message.success(res.message);
            } else {
              message.error(res.message);
            }
            onLoadFileList();
          });
        },
        onCancel() {
          // console.log("Cancel");
        },
      });
    };

    return {
      formState,
      pagination,
      onLoadFileList,
      treeData,
      engineList,
      update_visible,
      formUpdateState,
      updateFileInfo,
      handleSubmit,
      deleteFileConfirm,
      columns: [
        {
          title: "文件ID",
          dataIndex: "file_id",
          key: "file_id",
          width: "10%",
        },
        {
          title: "文件预览",
          dataIndex: "full_path",
          key: "full_path",
          width: "10%",
        },
        {
          title: "文件名称",
          dataIndex: "file_name",
          key: "file_name",
          width: "30%",
        },
        {
          title: "上传时间",
          dataIndex: "create_time",
          key: "create_time",
          width: "20%",
        },
        {
          title: "存储方式",
          dataIndex: "storage",
          key: "storage",
          width: "10%",
        },
        {
          title: "文件大小(KB)",
          dataIndex: "file_size",
          key: "file_size",
          width: "10%",
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          width: "10%",
        },
      ],
    };
  },
});
</script>